
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { IProduct } from "@/types/product";
import StockList from "@/components/admin/list/StockList.vue";
import MovementList from "@/components/admin/list/MovementList.vue";
import { IProductStockListRequest } from "@/types/product_stock";
import { IWarehouseListRequest } from "@/types/warehouse";
import { ISelectItem } from "@/types";

@Component({
  components: { StockList, MovementList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  @Action("product/adminGet")
  public getProduct!: (id: number) => void;

  @Getter("product/single")
  public product!: IProduct;

  @Mutation("product/clear")
  public clear!: () => void;

  // product_stock_show
  @Action("product_index/adminGetStockShow")
  public getProductStock!: (params: IProductStockListRequest) => boolean;

  @Getter("product_index/all_quantity")
  public allQuantity!: number;
  @Getter("product_index/stock_by_warehouse")
  public byWarehouse!: [];

  @Mutation("product_index/clear")
  public stockClear!: () => void;

  // warehouse
  @Action("warehouse/adminGetList")
  public getWarehouse!: (params: IWarehouseListRequest) => boolean;

  @Getter("warehouse/selectItem")
  public warehouseList!: ISelectItem[];

  // fields
  public product_id = 0;

  public params: IProductStockListRequest = {
    product_id: 0
  };

  public items: { text: string; icon: string; to: string }[] = [];

  public async created() {
    this.product_id = Number(this.$route.params.product_id);
    this.params.product_id = this.product_id;

    this.items = [
      {
        text: "詳細",
        icon: "mdi-card-account-details-outline",
        to: `/admin/product/${this.product_id}`
      },
      {
        text: "顧客品番",
        icon: "mdi-numeric",
        to: `/admin/product/${this.product_id}/customer_product`
      }
    ];

    this.clear();
    this.stockClear();
    await Promise.all([
      this.getProduct(this.product_id),
      this.getProductStock(this.params),
      this.getWarehouse({ per_page: 0 })
    ]);
  }
}
